<template>
  <div class="wk_giacenze d-flex flex-column w-100">
    <div id="search-bar" class="bg-grey-lighten-3 py-3">
      <div class="wk_searchbar_row large-wrapper" id="search-bar-first-row">
        <v-combobox
            label="warehouse"
            :items="search_fields.magazzini"
            v-model="selectedMagazzino"
            class="wk_autocomplete"
            clearable
            hide-no-data
        >
          <!--          <template v-slot:item="{ props, item }">-->
          <!--            <v-list-item-->
          <!--              v-bind="props"-->
          <!--              :subtitle="item.raw.ALMAG ? item.raw.ALMAG : ''"-->
          <!--              :title="-->
          <!--                item.raw.ALDES !== '' ? item.raw.ALDES : 'Senza descrizione'-->
          <!--              "-->
          <!--            ></v-list-item>-->
          <!--          </template>-->
        </v-combobox>
        <v-combobox
            label="color code 1"
            :items="search_fields.colori1"
            item-title="CDCOL"
            item-name="CDCOL"
            item-value="CDCOL"
            v-model="selectedColore1"
            class="wk_autocomplete"
            clearable
            hide-no-data
            return-object
            @blur="handleBlur('CDCOL')"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                :title="item.raw.CDCOL !== '' ? item.raw.CDCOL : 'No code'"
                :subtitle="item.raw.CDCOL_DES ? item.raw.CDCOL_DES : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
            label="finish code 1 "
            :items="search_fields.finiture1"
            item-title="CDFIN_DES"
            item-name="CDFIN_DES"
            item-value="CDFIN"
            v-model="selectedFinitura1"
            class="wk_autocomplete"
            clearable
            hide-no-data
            return-object
            @blur="handleBlur('CDFIN')"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                :title="item.raw.CDFIN !== '' ? item.raw.CDFIN : 'No code'"
                :subtitle="item.raw.CDFIN_DES ? item.raw.CDFIN_DES : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
            label="length (mm)"
            :items="search_fields.lunghezze"
            v-model="selectedLunghezza"
            class="wk_autocomplete"
            clearable
            hide-no-data
        ></v-combobox>
        <v-combobox
            label="thickness (mm)"
            :items="search_fields.spessori"
            v-model="selectedSpessore"
            class="wk_autocomplete"
            clearable
            hide-no-data
        ></v-combobox>
        <v-combobox
            label="type code"
            :items="search_fields.tipi"
            v-model="selectedTipo"
            class="wk_autocomplete"
            clearable
            hide-no-data
        >
          <!--          <template v-slot:item="{ props, item }">-->
          <!--            <v-list-item-->
          <!--              v-bind="props"-->
          <!--              :title="item.raw.ALTIP !== '' ? item.raw.ALTIP : 'Senza codice'"-->
          <!--              :subtitle="item.raw.ALTIP_DESC ? item.raw.ALTIP_DESC : ''"-->
          <!--            ></v-list-item>-->
          <!--          </template>-->
        </v-combobox>
      </div>
      <div class="wk_searchbar_row large-wrapper" id="search-bar-second-row">
        <v-select
            :disabled="!!selectedMagazzino || !positionLoaded"
            label="my country"
            :items="countriesList"
            v-model="userPosition"
            class="wk_autocomplete"
            item-title="name"
            item-name="name"
            item-value="code"
            clearable
            hide-no-data
        ></v-select>
        <v-combobox
            label="color code 2"
            :items="search_fields.colori2"
            item-title="CDCO2"
            item-name="CDCO2"
            item-value="CDCO2"
            v-model="selectedColore2"
            class="wk_autocomplete"
            clearable
            hide-no-data
            return-object
            @blur="handleBlur('CDCO2')"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                :title="item.raw.CDCO2 !== '' ? item.raw.CDCO2 : 'No code'"
                :subtitle="item.raw.CDCOL_DES2 ? item.raw.CDCOL_DES2 : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
            label="finish code 2"
            :items="search_fields.finiture2"
            item-title="CDFIN_DES2"
            item-name="CDFIN_DES2"
            item-value="CDFI2"
            v-model="selectedFinitura2"
            class="wk_autocomplete"
            clearable
            hide-no-data
            return-object
            @blur="handleBlur('CDFI2')"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                :title="item.raw.CDFI2 !== '' ? item.raw.CDFI2 : 'No code'"
                :subtitle="item.raw.CDFIN_DES2 ? item.raw.CDFIN_DES2 : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
            label="width (mm)"
            :items="search_fields.larghezze"
            v-model="selectedLarghezza"
            class="wk_autocomplete"
            clearable
            hide-no-data
        ></v-combobox>
        <div class="d-flex justify-end" id="search-button">
          <v-btn @click="search()" variant="flat" color="black">Search</v-btn>
          <v-btn @click="reset()" variant="tonal" color="black">Reset</v-btn>
        </div>
      </div>
    </div>

    <v-alert
        class="my-1 large-wrapper"
        v-if="searched && filteredList.length === 0"
        text="No data available for your research."
        title="No result"
        type="error"
        density="comfortable"
        closable
    ></v-alert>

    <v-alert
        class="my-1 large-wrapper"
        v-if="searched && filteredList.length > 1000"
        text="The research has produced more than 1.000 results. We recommend increasing your search parameters to reduce the total number of results."
        title="Warning"
        type="warning"
        density="compact"
        closable
    ></v-alert>

    <div
        class="space-top align-center justify-center text-center"
        v-if="loading"
    >
      <v-progress-circular
          :size="70"
          :width="7"
          color="black"
          indeterminate
      ></v-progress-circular>
    </div>

    <DataTable
        v-else
        class="wk_prod_table large-wrapper p-datatable-sm"
        style="font-size: 0.875rem"
        v-show="filteredList.length > 0"
        :value="filteredList"
        :reorderable-columns="true"
        resizableColumns
        columnResizeMode="expand"
        stripedRows
        paginator
        :rows="50"
        :totalRecords="filteredList.length"
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        @row-click="showForm"
        :sortOrder="-1"
        scrollable
        scrollHeight="80vh"
    >
      <template #header>
        <div class="wk_columns_select text-right">
          Select the columns:
          <MultiSelect
              :modelValue="selectedColumns"
              :options="headerList"
              optionLabel="header"
              @update:modelValue="onToggle"
              :maxSelectedLabels="5"
              class="ma-1"
          />
        </div>
      </template>

      <Column field="DISP" header="Availability" sortable>
        <template #body="{ data }">
          <div
              class="d-flex wk-circle"
              :style="{
              backgroundColor: availabilityColor(data.DISP),
            }"
              v-tooltip.bottom="setTooltip(data.DISP)"
          ></div>
        </template>
      </Column>

      <Column
          v-for="(col, index) of selectedColumns"
          :key="col.field + '_' + index"
          :field="col.field"
          :header="col.header"
          sortable
      >
        <template #body="{ data }">
          {{ printColumn(col.field, data) }}
        </template>
      </Column>
    </DataTable>

    <div class="wk_post_table large-wrapper">
      <v-card class="wk_disclaimer" elevation="0">
        <v-card-title class="pa-0">Disclaimer</v-card-title>
        <v-card-text>
          <ol>
            <li>
              <span>
                In order to offer the best service, it is mandatory to fill in
                all fields
              </span>
            </li>
            <li>
              <span>
                Accuracy in filling in all fields is recommended, in order to
                allow the relevant Customer Service to contact the customer
                quickly.
              </span>
            </li>
            <li>
              <span>
                The availability of the material (divided into availability
                groups) is relative to the moment in which the customer is
                consulting the system. The definitive availability will be
                reconfirmed to the customer by the Abet Laminati Customer
                Service at the moment of contact.
              </span>
            </li>
            <li>
              <span>
                The request via this form is not binding, neither by the
                Customer nor by Abet Laminati, until the relevant Customer
                Service has got in touch with the customer to define the order.
              </span>
            </li>
            <li>
              <span>
                The prices that will be communicated to the customer at the
                moment of the effective order will take into account any extra
                shipping costs and/or customs clearance costs, if the material
                is available in a warehouse outside the customer's country.
              </span>
            </li>
            <!--
            <li>
              <span>
                If you do not receive a response within 48 hours of sending the
                request, please contact the international customer service at
                the email address:
                <a href="mailto:customer.service@abetlaminati.com"
                  >customer.service@abetlaminati.com</a
                >
                indicating the request number.
              </span>
            </li>
            -->
          </ol>
        </v-card-text>
      </v-card>
      <v-card class="wk_legend" elevation="0">
        <v-card-title class="pa-0">Legend</v-card-title>
        <v-card-text>
          <div class="wk_legend_wrapper">
            <div class="wk_legend_item">
              <div
                  class="wk-circle"
                  :style="{
                  backgroundColor: availabilityColor(3),
                }"
              ></div>
              <div>High availability</div>
            </div>
            <div class="wk_legend_item">
              <div
                  class="wk-circle"
                  :style="{
                  backgroundColor: availabilityColor(2),
                }"
              ></div>
              <div>Medium availability</div>
            </div>
            <div class="wk_legend_item">
              <div
                  class="wk-circle"
                  :style="{
                  backgroundColor: availabilityColor(1),
                }"
              ></div>
              <div>Low availability</div>
            </div>
            <div class="wk_legend_item">
              <div
                  class="wk-circle"
                  :style="{
                  backgroundColor: availabilityColor(0),
                }"
              ></div>
              <div>To be produced</div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>

  <v-dialog
      class="wk_popup_request"
      v-model="dialog"
      max-width="700px"
      close-on-back
  >
    <v-card v-if="selectedStock">
      <v-card-text
          class="bg-grey-lighten-3 text-center d-flex flex-column py-5"
          style="font-size: 12px"
      >
        <v-icon
            class="wk_close_popup_request"
            size="large"
            icon="mdi-close"
            @click="dialog = false"
        ></v-icon>

        <span
        ><b>{{ selectedStock.ALTIP ? selectedStock.ALTIP : "" }}</b>
          {{ selectedStock.ALTIP_DESC ? selectedStock.ALTIP_DESC : "" }}
        </span>
        <span
        ><b>{{ selectedStock.ALCOL ? selectedStock.ALCOL : "" }}</b>
          {{ selectedStock.ALCOL_DESC ? selectedStock.ALCOL_DESC : "" }} ·
          <b>{{ selectedStock.ALFIN ? selectedStock.ALFIN : "" }}</b>
          {{ selectedStock.ALFIN_DESC ? selectedStock.ALFIN_DESC : "" }}</span
        >

        <span
        ><b>{{ selectedStock.ALSPE ? selectedStock.ALSPE + "mm" : "" }}</b> ·
          {{ selectedStock.ALLUN ? selectedStock.ALLUN + "mm" : "" }} x
          {{ selectedStock.ALLAR ? selectedStock.ALLAR + "mm" : "" }}</span
        >
      </v-card-text>
      <v-card-text class="my-0 pb-2 pt-4" style="font-size: 0.875rem"
      >You're sending a request to
      </v-card-text>
      <v-card-title class="mx-2 my-0 py-0">
        {{
          // selectedStock.MAG_DESC ? selectedStock.MAG_DESC : selectedStock.MAG_COD
          contact.ALDES
        }}
      </v-card-title>
      <v-card-subtitle class="mx-2" v-if="contact">
        {{ contact.INDIRIZZO }} <br/>
        {{ contact.CITTA }} ({{ contact.ZIP_CODE }}), {{ contact.PAESE }}
      </v-card-subtitle>

      <v-card-text
          v-if="!request.sent && !request.error && !request.cooldown && contact"
      >
        <v-form ref="form" v-model="request.valid" @submit.prevent="submitForm">
          <div class="d-flex flex-row">
            <v-text-field
                class="w-50 wk_request_field"
                v-model="request.name"
                :rules="[(v) => !!v || 'Insert the name.']"
                label="name*"
                placeholder="name"
                required
            ></v-text-field>

            <v-text-field
                class="w-50 wk_request_field"
                v-model="request.surname"
                :rules="[(v) => !!v || 'Insert the surname.']"
                label="surname*"
                placeholder="surname"
                required
            ></v-text-field>
          </div>

          <div class="d-flex flex-row">
            <v-text-field
                class="w-50 wk_request_field"
                v-model="request.mail"
                :rules="[
                (v) => !!v || 'Insert the e-mail.',
                (v) => /.+@.+\..+/.test(v) || 'E-mail not valid',
              ]"
                label="e-mail*"
                placeholder="e-mail"
                required
            ></v-text-field>

            <v-select
                class="wk_request_field"
                style="width: 18%"
                :items="countryPhoneCodes"
                v-model="request.prefix"
                :rules="[(v) => !!v || 'Select a prefix number.']"
                item-title="code"
                item-name="code"
                item-value="code"
                label="prefix*"
                placeholder="+39"
                required
            >
              <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :title="item.raw.code"
                    :subtitle="item.raw.country"
                ></v-list-item>
              </template>
            </v-select>

            <v-text-field
                class="wk_request_field"
                style="width: 32%"
                v-model="request.phone"
                :rules="[
                (v) => !!v || 'Insert the phone number.',
                (v) => /^(\d[- ]?){3,15}$/.test(v) || 'Phone number not valid',
              ]"
                label="phone number*"
                placeholder="333 1234 567"
                required
            ></v-text-field>
          </div>

          <div class="d-flex flex-row">
            <v-text-field
                class="w-50 wk_request_field"
                v-model="request.company"
                :rules="[(v) => !!v || 'Insert the company.']"
                label="company*"
                placeholder="company"
                required
            ></v-text-field>

            <v-select
                class="w-50 wk_request_field"
                :items="countriesList"
                v-model="request.country"
                :rules="[(v) => !!v || 'Select a country.']"
                item-title="name"
                item-name="name"
                item-value="code"
                label="country*"
                placeholder="Italy"
                required
            ></v-select>
          </div>

          <div class="d-flex flex-row">
            <v-text-field
                class="w-50 wk_request_field"
                v-model="request.quantity"
                :rules="[
                (v) => !!v || 'Insert the quantity (in panel).',
                (v) => /^[1-9]\d*$/.test(v) || 'Quantity is not valid',
              ]"
                label="quantity*"
                placeholder="e.g. 10"
                required
            ></v-text-field>
            <div class="w-50"></div>
          </div>

          <v-textarea
              :counter="300"
              rows="3"
              class="wk_request_field"
              v-model="request.notes"
              :rules="[(v) => !!v || 'Insert a message.']"
              label="message*"
              required
          ></v-textarea>

          <div class="d-flex w-100 align-content-center">
            <v-btn
                v-if="!request.sent && !request.error && !request.cooldown"
                class="wk_custom_send_button w-100 mx-auto"
                style="max-width: 100px"
                color="black"
                :disabled="!request.valid"
                @click="submitForm()"
            >Submit
            </v-btn>
            <v-btn
                v-else
                class="w-100 mx-auto"
                style="max-width: 100px"
                color="black"
                @click="closeForm()"
            >Close
            </v-btn>
          </div>
        </v-form>
      </v-card-text>

      <v-card-text class="font-weight-bold" v-if="request.sent">
        Your request has been registered.
      </v-card-text>

      <v-card-text class="font-weight-bold" v-if="request.error">
        There was an error sending your request. Please try again later.
      </v-card-text>

      <v-card-text class="font-weight-bold" v-if="request.cooldown">
        Too many request. Please try again later.
      </v-card-text>

      <v-card-text v-if="!contact">
        It is not possible to send the request now. Please try again later.
      </v-card-text>
    </v-card>
  </v-dialog>

  <!--

  <v-btn
    variant="tonal"
    color="black"
    class="wk_table_legend_button"
    text="Legend"
    @click="sheet = !sheet"
  ></v-btn>

  <v-bottom-sheet v-model="sheet" inset>
    <v-card class="text-center" height="250">
      <v-card-text style="align-content: center">
        <v-btn variant="text" color="black" @click="sheet = !sheet"
          >Close
        </v-btn>
        <div class="wk_column_legend">
          <div class="wk_row_legend">
            <div
              class="d-flex my-1 mx-2 wk-circle"
              :style="{
                backgroundColor: availabilityColor(3),
              }"
            ></div>
            <div>High availability</div>
          </div>
          <div class="wk_row_legend">
            <div
              class="d-flex my-1 mx-2 wk-circle"
              :style="{
                backgroundColor: availabilityColor(2),
              }"
            ></div>
            <div>Medium availability</div>
          </div>
          <div class="wk_row_legend">
            <div
              class="d-flex my-1 mx-2 wk-circle"
              :style="{
                backgroundColor: availabilityColor(1),
              }"
            ></div>
            <div>Low availability</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>

  -->
</template>

<style lang="scss" scoped>
@import "@/styles/styles";

.wk-circle {
  border-radius: 50%;
  width: 20px;
  padding-top: 20px;
}

.wk_giacenze {
  padding-bottom: 100px;
}

.large-wrapper {
  margin: 0 auto;
  width: 90%;
}

#search-button {
  max-width: calc(33.33% - 20px);
  width: 33.33%;
  margin: 20px 10px;
}

.wk_autocomplete {
  max-width: calc(16.66% - 20px);
  margin: 0 10px;
}

.space-top {
  margin-top: 80px;
}

.wk_searchbar_row {
  display: flex;
  justify-content: flex-start;
}

.wk_columns_select .p-multiselect .p-multiselect-label {
  padding: 10px !important;
}

.v-data-table-header__content span {
  font-weight: 600;
}

// remove margin bottom 100px
.p-datatable.p-component.p-datatable-responsive-scroll {
  margin-bottom: 0;
}

.p-multiselect .p-multiselect-label,
.p-multiselect .p-multiselect-trigger {
  padding: 0.3rem 0.5rem !important;
}

.wk_request_field {
  padding: 0 6px;
}

.wk_table_legend_button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.wk_post_table {
  display: flex;
  flex-direction: row;
}

.wk_legend {
  width: 30%;
  margin: 20px 0;
  padding: 10px 0 10px 20px;
  border-left: 1px solid rgb(118, 118, 118);
  font-size: 0.875rem;

  .wk_legend_wrapper {
    display: flex;
    flex-direction: column;
  }

  .wk-circle {
    margin: 0 12px 0 8px;
  }

  .wk_legend_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;

    &:first-child {
      margin-top: 15px;
    }
  }
}

.wk_disclaimer {
  width: 70%;
  margin: 20px 0;
  padding: 10px 20px 10px 0;
  color: rgba(0, 0, 0, 0.87);

  ol {
    list-style: decimal;
    padding-left: 30px;

    li {
      font-size: 1.5rem;
      line-height: 1.125em;
      font-weight: bold;
      margin: 15px 0;
    }

    li span {
      font-size: 0.875rem;
      line-height: 1.125em;
      font-weight: normal;
    }

    li a {
      text-decoration: none;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.wk_custom_send_button:disabled {
  opacity: 0.2;
}

.wk_close_popup_request {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
</style>

<script>
import axios from "axios";
import fullsearch_fields_json from "../data/fullsearch_fields_prod.json";
import countries_json from "../data/countries.json";
import country_phone_codes_json from "../data/countryPhoneCodes.json";
import {API_URL, countries} from "@/utils/const";
import authHeader from "@/services/data.service";
import {mapGetters} from "vuex";
import {availabilityColor} from "@/utils/helper";

export default {
  data() {
    return {
      ready: false,
      clearable: true,
      loading: false,
      // search_fields: [],
      // data from APIs
      search_fields: {
        tipi: [],
        colori1: [],
        colori2: [],
        finiture1: [],
        finiture2: [],
        spessori: [],
        magazzini: [],
        lunghezze: [],
        larghezze: [],
      },
      // selected data
      selectedTipo: null,
      selectedColore1: null,
      selectedColore2: null,
      selectedFinitura1: null,
      selectedFinitura2: null,
      selectedSpessore: null,
      selectedMagazzino: null,
      selectedLunghezza: null,
      selectedLarghezza: null,
      selectedColumns: [],
      userPosition: null,
      positionFound: false,
      positionLoaded: false,
      headerList: [
        // { field: "ALMAG", header: "Codice magazzino" },
        {field: "MAG_DESC", header: "Warehouse"},
        // { field: "DIST", header: "Distance" },
        {field: "ALTIP", header: "Cod. Type"},
        // { field: "ALTIP_DESC", header: "Desc. Type" },
        {field: "ALCOL", header: "Col. 1"},
        // { field: "ALCOL_DESC", header: "Desc. col. 1" },
        {field: "ALFIN", header: "Fin. 1"},
        // { field: "ALFIN_DESC", header: "Desc. fin. 1" },
        {field: "ALSPE", header: "Thick. (mm)"},
        {field: "ALDIM", header: "Dimension"},
        {field: "ALLUN", header: "Length (mm)"},
        {field: "ALLAR", header: "Width. (mm)"},
        {field: "ALCO2", header: "Col. 2"},
        // { field: "ALCO2_DESC", header: "Desc. col. 2" },
        {field: "ALFI2", header: "Fin. 2"},
        // { field: "ALFI2_DESC", header: "Desc. fin. 2" },
        // { field: "Pannelli_Disp", header: "Pannelli disponibili (pz)" },
        // { field: "ALDIS", header: "Disponibilità (mq)" },
        // { field: "ALCAR", header: "Codice CAR" },
        {field: "ALLT1", header: "Proc. 1"},
        // { field: "ALLT1_DESC", header: "Desc. proc. 1" },
        {field: "ALLT2", header: "Proc. 2"},
        // { field: "ALLT2_DESC", header: "Desc. proc. 2" },
      ],
      filteredList: [],
      isFullsearch: null,
      searched: false,
      dialog: false,
      sheet: false,
      selectedStock: null,
      contact: null,
      showResume: false,
      request: {
        name: null,
        surname: null,
        mail: null,
        prefix: null,
        phone: null,
        company: null,
        country: null,
        quantity: null,
        notes: null,
        valid: false,
        sent: false,
        error: false,
        cooldown: false,
      },
      countriesList: null,
    };
  },
  computed: {
    ...mapGetters("stock", ["magazzini"]),
  },
  created() {
    this.search_fields = fullsearch_fields_json;
    this.countriesList = countries_json;
    this.countryPhoneCodes = country_phone_codes_json;
  },
  mounted() {
    if (this.$route.meta.title) {
      document.title = this.$route.meta.title;
    }

    this.loading = true;

    if (!this.magazzini) {
      this.getMagazzini();
    }
    // } else {
    //   this.search_fields.magazzini = this.magazzini;
    // }

    this.selectedColumns = this.headerList;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success, this.errorHandler);
    } else {
      this.error = "Geolocation is not supported by this browser.";
    }

    this.loading = false;
  },
  methods: {
    countries() {
      return countries;
    },
    async success(position) {
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;

      try {
        await axios
            .get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${process.env.VUE_APP_GEOCODING_APIKEY}`
            )
            .then((response) => {
              if (response.data.status === "OK") {
                const addressComponents =
                    response.data.results[0].address_components;
                const countryComponent = addressComponents.find((component) =>
                    component.types.includes("country")
                );
                this.userPosition = countryComponent.short_name;
                this.request.country = countryComponent.short_name;
                this.positionFound = true;
              } else {
                this.error = "Unable to determine location.";
              }
            })
            .catch(() => {
              this.error = "An error occurred while retrieving location.";
            });
      } catch (error) {
        this.error = "Unable to determine location.";
      }
      this.positionLoaded = true;
    },
    errorHandler(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.error = "User denied the request for Geolocation.";
          break;
        case error.POSITION_UNAVAILABLE:
          this.error = "Location information is unavailable.";
          break;
        case error.TIMEOUT:
          this.error = "The request to get user location timed out.";
          break;
        case error.UNKNOWN_ERROR:
          this.error = "An unknown error occurred.";
          break;
      }
      this.positionLoaded = true;
    },
    availabilityColor,
    async getMagazzini() {
      await axios
          .get(API_URL + "contacts/warehouses/all")
          .then((response) => {
            if (response.data.status === "success") {
              this.search_fields.magazzini = response.data.data;
              this.$store.dispatch("stock/updateMagazzini", response.data.data);
            }
          })
          .catch((error) =>
              console.error("Error while getting magazzini", error)
          );
    },
    async search() {
      this.loading = true;
      this.searched = false;

      const queryParams = {
        tipo: this.selectedTipo ? this.selectedTipo : null,
        colore1: this.selectedColore1 ? this.selectedColore1.CDCOL : null,
        colore2: this.selectedColore2 ? this.selectedColore2.CDCO2 : null,
        finitura1: this.selectedFinitura1 ? this.selectedFinitura1.CDFIN : null,
        finitura2: this.selectedFinitura2 ? this.selectedFinitura2.CDFI2 : null,
        spessore: this.selectedSpessore,
        magazzino: this.selectedMagazzino ? this.selectedMagazzino : null,
        // dimensione: this.selectedDimensione ? this.selectedDimensione : null,
        lunghezza: this.selectedLunghezza ? this.selectedLunghezza : null,
        larghezza: this.selectedLarghezza ? this.selectedLarghezza : null,
        posizione: this.selectedMagazzino
            ? null
            : this.userPosition
                ? this.userPosition
                : null,
      };
      await axios
          .get(API_URL + "warehouse/stock/fullsearch", {
            headers: authHeader(),
            params: queryParams,
          })
          .then((response) => {
            if (response.data.data) {
              this.filteredList = response.data.data.stocks;

              this.filteredList.sort((a, b) => {
                if (b.DIST !== a.DIST) {
                  return a.DIST - b.DIST;
                } else {
                  return b.DISP - a.DISP;
                }
              });

              this.isFullsearch = response.data.data.fullsearch;
            } else {
              this.filteredList = null;
            }
          })
          .catch((error) => {
            console.error("Error while getting the search result", error);
          });

      if (window.plausible) {
        const props = {};
        const addProp = (key, value) => {
          if (value !== null && value !== undefined) props[key] = value;
        };

        addProp("warehouse", this.selectedMagazzino);
        addProp("type_code", this.selectedTipo);
        addProp("color_code_first", this.selectedColore1 ? this.selectedColore1.CDCOL : null);
        addProp("finish_code_first", this.selectedFinitura1 ? this.selectedFinitura1.CDFIN : null);
        addProp("color_code_second", this.selectedColore2 ? this.selectedColore2.CDCO2 : null);
        addProp("finish_code_second", this.selectedFinitura2 ? this.selectedFinitura2.CDFI2 : null);
        addProp("thickness", this.selectedSpessore);
        addProp("length", this.selectedLunghezza);
        addProp("width", this.selectedLarghezza);
        addProp("collection", this.selectedCollezione);

        window.plausible("Search in stocks", {props});
      }

      this.loading = false;
      this.searched = true;
    },
    async findContact(code) {
      await axios
          .get(API_URL + `contacts/${code}`, {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.data.data) {
              this.contact = response.data.data;
            }
          })
          .catch((error) => {
            console.error("Error while finding the contact", error);
          });
    },
    onToggle(value) {
      this.selectedColumns = this.headerList.filter((col) =>
          value.includes(col)
      );
    },
    async sendRequest() {
      const body = {
        name: this.request.name,
        surname: this.request.surname,
        mail: this.request.mail,
        phone: `${this.request.prefix} ${this.request.phone}`,
        company: this.request.company,
        country: this.request.country,
        quantity: this.request.quantity,
        notes: this.request.notes,
        ALMAG: this.selectedStock.MAG_COD ? this.selectedStock.MAG_COD : "NA",
        ALTIP: this.selectedStock.ALTIP,
        ALFIN: this.selectedStock.ALFIN,
        ALCOL: this.selectedStock.ALCOL,
        ALFI2: this.selectedStock.ALFI2,
        ALCO2: this.selectedStock.ALCO2,
        ALSPE: this.selectedStock.ALSPE,
        ALDIM: this.selectedStock.ALDIM,
        ALLAR: this.selectedStock.ALLAR,
        ALLUN: this.selectedStock.ALLUN,
      };

      await axios
          .post(API_URL + "warehouse/stock/send-request", body, {
            headers: authHeader(),
          })
          .then((result) => {
            if (result && result.data.status) {
              this.request.sent = true;
              setTimeout(() => {
                this.request.sent = false;
              }, 5000);
            }
          })
          .catch((error) => {
            console.log(error.response?.status);
            if (error.response?.status === 429) {
              this.request.cooldown = true;
              setTimeout(() => {
                this.request.cooldown = false;
              }, 15000);
            } else {
              this.request.error = true;
              setTimeout(() => {
                this.request.error = false;
              }, 3000);
            }
          });
    },
    async showForm(event) {
      this.selectedStock = event.data;
      let code = this.selectedStock.MAG_COD ? this.selectedStock.MAG_COD : "8";
      if (code) {
        await this.findContact(code);
        this.dialog = true;
      }
    },
    closeForm() {
      this.dialog = false;
      this.contact = null;
      this.selectedStock = null;
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.sendRequest().finally(() => {
          this.request.name = null;
          this.request.surname = null;
          this.request.mail = null;
          this.request.phone = null;
          this.request.company = null;
          this.request.quantity = null;
          this.request.notes = null;
        });

        this.$refs.form.resetValidation();
      }
    },
    reset() {
      this.selectedTipo = null;
      this.selectedColore1 = null;
      this.selectedColore2 = null;
      this.selectedFinitura1 = null;
      this.selectedFinitura2 = null;
      this.selectedSpessore = null;
      this.selectedLunghezza = null;
      this.selectedLarghezza = null;
      this.selectedMagazzino = null;
      this.filteredList = [];
      this.searched = false;
      this.selectedStock = false;
      this.contact = null;
      this.dialog = false;
    },
    handleBlur(field) {
      switch (field) {
        case "CDCOL":
          if (typeof this.selectedColore1 === "string") {
            this.selectedColore1 = {
              CDCOL: this.selectedColore1,
              CDCOL_DES: "",
            };
          }
          break;
        case "CDCO2":
          if (typeof this.selectedColore2 === "string") {
            this.selectedColore2 = {
              CDCO2: this.selectedColore2,
              CDCOL_DES2: "",
            };
          }
          break;
        case "CDFIN":
          if (typeof this.selectedFinitura1 === "string") {
            this.selectedFinitura1 = {
              CDFIN: this.selectedFinitura1,
              CDFIN_DES: "",
            };
          }
          break;
        case "CDFI2":
          if (typeof this.selectedFinitura2 === "string") {
            this.selectedFinitura2 = {
              CDFI2: this.selectedFinitura2,
              CDFI2_DES: "",
            };
          }
          break;
        default:
          break;
      }
    },
    setTooltip(disp) {
      switch (disp) {
        case 1:
          return "Low availability";
        case 2:
          return "Medium availability";
        case 3:
          return "High availability";
        default:
          return "To be produced";
      }
    },
    printColumn(field, data) {
      switch (field) {
        case "ALTIP":
          return data.ALTIP_DESC
              ? data.ALTIP + " - " + data.ALTIP_DESC
              : data.ALTIP;
        case "ALFIN":
          return data.ALFIN_DESC
              ? data.ALFIN + " - " + data.ALFIN_DESC
              : data.ALFIN;
        case "ALCOL":
          return data.ALCOL_DESC
              ? data.ALCOL + " - " + data.ALCOL_DESC
              : data.ALCOL;
        case "ALFI2":
          return data.ALFI2_DESC
              ? data.ALFI2 + " - " + data.ALFIN_DESC
              : data.ALFI2;
        case "ALCO2":
          return data.ALCO2_DESC
              ? data.ALCO2 + " - " + data.ALCO2_DESC
              : data.ALCO2;
        case "ALLT1":
          return data.ALLT1_DESC
              ? data.ALLT1 + " - " + data.ALLT1_DESC
              : data.ALLT1;
        case "ALLT2":
          return data.ALLT2_DESC
              ? data.ALLT2 + " - " + data.ALLT2_DESC
              : data.ALLT2;
        default:
          return data[field];
      }
    },
  },
};
</script>
