// utils
export const countries = [
    "au",
    "be",
    "cn",
    "fr",
    "de",
    "it",
    "nl",
    "na",
    "pl",
    "ru",
    "es",
    "ch",
    "uk",
    "ua"
];

// display messages
export const notifyLoading = "Loading completed";
export const notifySaving = "Saved successfully";
export const notifyError = "Something went wrong. Try again later";
export const notifyDeletion = "Deleted successfully";
export const notifyConsAlreadyExist =
    "Standard already exists for this branch";

export const restoreConfirmation =
    "Cliccando su conferma, procederai al ripristino dei dati di producibilità più recenti. Tutti i dati di producibilità aggiunti sul sito verranno cancellati. Sei sicuro di voler continuare?";
export const duplicateConfirmation =
    "Cliccando su conferma, procederai alla creazione di un nuovo set di dati di producibilità modificabili dal sito. I dati precenti non verranno cancellati.";

export const defaultTimeout = 2000;
// url
export const THIS_URL =
    process.env.VUE_APP_NODE_ENV === "production"
        ? process.env.VUE_APP_THIS_URL_PROD
        : process.env.VUE_APP_THIS_URL_DEV;
export const API_URL =
    process.env.VUE_APP_NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL_PROD
        : process.env.VUE_APP_API_URL_DEV;
